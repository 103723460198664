import * as React from 'react'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import Layout from '@components/layout'

const MuliYearPlanPage = () => {
  return (
    <Layout pageTitle="Multi Year Plan">
      <main>
        <Container fluid="xxl">
          <Row>
            <Col>
              <h3 className="default-text">Multi-year Accessibility Plan</h3>
              <div className="section">
                <Table>
                  <tr>
                    <th>Regulation</th>
                    <th>Actions</th>
                    <th>Status</th>
                    <th>Year of Compliance</th>
                  </tr>
                  <tbody>
                    <tr>
                      <td>Establishment of Policies</td>
                      <td>Create a Statement of Commitment</td>
                      <td>Complete</td>
                      <td>2017</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Create an Accessibility Policy and post it on the Safety Board</td>
                      <td>Updated</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Have a copy of both the Accessibility and Statement of Commitment in an accessible format, should the documents be requested</td>
                      <td>Reviewed and Updated</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Create a feedback policy</td>
                      <td>Complete</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td>Establishment of Plans</td>
                      <td>Create a multi-year accessibility plan</td>
                      <td>Complete</td>
                      <td>2017</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Review the multi-year plan</td>
                      <td>Complete, to be reviewed annually</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Upon request, provide a copy of the multi-year plan in an accessible format</td>
                      <td>Complete</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td>Integrated Accessibility Standards</td>
                      <td>Upon request, provide or make arrangements for accessible formats and communication supports when a person with a disability requests them</td>
                      <td>Future, as required</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Include accessibility in the recruitment and selection process by notifying job candidates that accommodations are available upon request for people with disabilities</td>
                      <td>Complete, Ongoing, Future as required</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Include accessibility in the performance management and career development processes for employees with disabilities as necessary</td>
                      <td>Complete, Ongoing</td>
                      <td>2016</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Provide individualized workplace emergency response plans for employees with disabilities, as needed</td>
                      <td>Future, as required</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Create individual accommodation plans for employees with disabilities</td>
                      <td>Future, as required</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Create a return to work plan for employees who have been off work because of a disability</td>
                      <td>Complete, Future as required</td>
                      <td>2017</td>
                    </tr>
                    <tr>
                      <td>Training</td>
                      <td>Train Employees on the Accessibility for Ontarians with Disabilities Act (AODA) and Ontario Human Rights Code (OHRC).</td>
                      <td>Complete, Ongoing, Updated</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Train Employees on the Integrated Accessibility Standards Regulation</td>
                      <td>Complte, Ongoing, Updated</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td>Customer Service Standards</td>
                      <td>Create an Accessible Customer Service Standards Policy</td>
                      <td>Complete</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Create or procure an AODA Customer Service Standard Training Module</td>
                      <td>Ongoing</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Incorporate Customer Service Accessibility into new hire orientations</td>
                      <td>Complete, Ongoing</td>
                      <td>2017</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Create an accessibility feedback mechanism</td>
                      <td>Future, as required</td>
                      <td>2021</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default MuliYearPlanPage